import { useEffect } from "react";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { useForm } from "react-hook-form";
import ReturnKitIcon from "../../../../../assets/return_kit.svg";
import styles from "./ReturnKit.module.css";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import {
  kitByBarcodeQuery,
  kitByCapscanSnQuery,
} from "../../../../../queries/kit.query";
import { Tab, Tabs } from "react-bootstrap";
import { getFormattedLocalDate } from "../../../../../util/date.util";
import useKit from "../../../../../hooks/useKit";
import { kitStatuses } from "../../../../clinical/ClinicalRow/ClinicalRow";
import { statuses } from "../../../../../components/Status/Status";
import useCapscan from "../../../../../hooks/useCapscan";

type Disposition = "destroy" | "returnToInventory";

type ReturnKitProps = {
  kitNumber: string;
  returnDate: string;
  disposition: Disposition;
  capscanSn: string;
  reference: string;
};

const dispositions = (name: string) => [
  { key: "destroy", value: "Destroy" },
  { key: "returnUnusedKit", value: `Return ${name} to inventory` },
];

const ReturnForm = (name: "CapScan Device" | "Kit") => {
  const { control, handleSubmit, setValue, watch, setError } =
    useForm<ReturnKitProps>();
  const intl = useIntl();

  const watchKitNumber = watch("kitNumber");
  const watchSn = watch("capscanSn");
  const watchDisposition = watch("disposition");

  const { data: kitByBarcode, refetch: refetchKit } = useQuery(
    kitByBarcodeQuery(watchKitNumber, {
      enabled: false,
    })
  );

  const { data: kitBySn, refetch: refetchKitBySn } = useQuery(
    kitByCapscanSnQuery(watchSn, {
      enabled: false,
    })
  );

  const { returnKit } = useKit();
  const { returnCapscan } = useCapscan();

  const navigate = useNavigate();

  const handleEnter = async (
    e: React.KeyboardEvent<Element>,
    refetch: () => void
  ) => {
    e.preventDefault();
    refetch();
  };

  const getKitStatus = (disposition: Disposition) =>
    kitStatuses.findIndex((status) =>
      disposition === "destroy"
        ? status === statuses.DESTROY
        : statuses.RETURN_UNUSED_KIT
    );

  const onSubmit = async (formState: ReturnKitProps) => {
    if (name === "Kit") {
      if (kitByBarcode?.data) {
        if (kitByBarcode.data?.subjectId) {
          alert("Could not return kit that already assigned");
          setError("kitNumber", {});
        } else {
          const { disposition } = formState;
          const response = await returnKit({
            kitBarcode: kitByBarcode.data.kitBarcode,
            status: getKitStatus(disposition),
          });
          setValue("kitNumber","");

        }
      }
    } else {
      if (kitBySn?.data) {
        if (kitBySn.data?.subjectId) {
          alert("Could not return capscan that already assigned");
          setError("capscanSn", {});
        } else {
          const { capscanSn, disposition } = formState;
          const response = await returnCapscan({
            capscanSn,
            status: dispositions("CapScan Device").findIndex(
              (item) => item.key === disposition
            ),
          });
          setValue("reference","");
          setValue("capscanSn","");

        }
      }
    }
  };

  useEffect(() => {
    if (kitBySn) {
      setValue(
        "reference",
        kitBySn?.data?.capscans
          ?.find((c) => c.sn === watchSn)
          ?.reference?.toString() || ""
      );
    }
  }, [kitBySn]);

  return (
    <>
      <FormattedMessage
        id={`ENVIVO.RETURN_KIT.SCAN_${name.toUpperCase()}`}
        defaultMessage={`Scan ${name} and select its Disposition`}
      />
      <div>
        <div className={`${name === "Kit" && "d-flex"}`}>
          <FormInput
            type="text"
            name="returnDate"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={getFormattedLocalDate(new Date(), "YYYY-MM-DD")}
            input={{
              type: "text",
              placeholder: intl.formatMessage({
                id: "ENVIVO.RETURN_KIT.DATE",
                defaultMessage: "Date Of Return",
              }),
              className: `${styles.input} ${styles.disabled} me-2 mb-2`,
              disabled: true,
            }}
          />
          {name === "Kit" ? (
            <div>
              <div className="d-flex">
                <FormInput
                  type="text"
                  name="kitNumber"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    placeholder: intl.formatMessage({
                      id: "ENVIVO.RETURN_KIT.KIT_NUMBER",
                      defaultMessage: "Kit Number",
                    }),
                    className: styles.input,
                    onEnterKey: (e) => handleEnter(e, refetchKit),
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <FormInput
                type="text"
                name="capscanSn"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  type: "text",
                  placeholder: "CapScan SN",
                  className: `${styles.input} me-2 mb-2`,
                  onEnterKey: (e) => handleEnter(e, refetchKitBySn),
                }}
              />
              <FormInput
                type="text"
                name="reference"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  type: "text",
                  placeholder: "Type",
                  className: styles.input,
                  disabled: true,
                }}
              />
            </div>
          )}
        </div>
        {name === "Kit" && kitByBarcode?.data?.capscans && (
          <div className="me-5">
            Devices:{" "}
            {kitByBarcode?.data?.capscans.map(({ sn }, index) =>
              index + 1 === kitByBarcode?.data?.capscans.length ? sn : `${sn}, `
            )}
          </div>
        )}
        <div>
          <div>
            <FormInput
              type="select"
              name="disposition"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                items: dispositions(name),
                placeholder: intl.formatMessage({
                  id: "ENVIVO.RETURN_KIT.DISPOSITION",
                  defaultMessage: "Disposition",
                }),
                className: `${styles.input} ${styles.disposition}`,
                disabled: name === "Kit" ? !kitByBarcode?.data : !kitBySn?.data,
              }}
            />
            <div className="w-50">
              <Button
                disabled={!watchDisposition}
                variant="outline-dark"
                className="border-dark rounded-1 ms-auto me-5"
                onClick={handleSubmit(onSubmit)}
              >
                <FormattedMessage
                  id={"ENVIVO.RETURN_KIT.APPLY"}
                  defaultMessage={"Apply"}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReturnKit = () => {
  const navigate = useNavigate();
  return (
    <>
      <FormModal
        id="device"
        className={`modal-lg modal-dialog-centered modal-dialog-scrollable mt-5`}
        dialogClassName="h-75 w-75"
        contentClassName="h-75 w-100"
        formClassName="h-100 ms-4"
        header={
          <div>
            <BaseIcon icon={ReturnKitIcon} />{" "}
            <b>
              <FormattedMessage
                id="ENVIVO.RETURN_KIT"
                defaultMessage="Return Kit"
              />
            </b>
          </div>
        }
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border-dark"
              onClick={() => navigate(-1)}
            >
              <FormattedMessage
                id={"ENVIVO.MODAL.EXIT"}
                defaultMessage="Exit"
              />
            </Button>
          </>
        }
        show={true}
        handleClose={() => navigate(-1)}
      >
        <Tabs>
          <Tab title="Kit" eventKey="Kit">
            {ReturnForm("Kit")}
          </Tab>
          <Tab title="CapScan Device" eventKey="CapScan">
            {ReturnForm("CapScan Device")}
          </Tab>
        </Tabs>
      </FormModal>
    </>
  );
};
export default ReturnKit;
